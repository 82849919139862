<template>
  <div class="update-membership-type-container wrapper">
    <CRow class>
      <CCol lg="6" md="6">
        <CCard>
          <CCardHeader>
            <h1>{{$t("MEMBERSHIP_TYPES_COMPONENT_TITLE_MEMBERSHIP_TYPE")}}</h1>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody v-if="!isLoading">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <h2>{{$t("MEMBERSHIP_TYPES_COMPONENT_TITLE_FREE_MEMBERSHIP")}}:</h2>

                <div class="form-free-membership">
                  <label class>{{$t("MEMBERSHIP_TYPES_COMPONENT_TITLE_FREE_MEMBERSHIP")}}:</label>
                  <div class="btn-switch-wrapper">
                    <label class="btn-switch">
                      <input type="checkbox" class="checkbox" v-model="isEnabledFreeMembership" />
                      <span class="check-silder"></span>
                    </label>
                  </div>
                  <span
                    :class="isEnabledFreeMembership?'active':'deactive'"
                  >{{isEnabledFreeMembership?$t('MEMBERSHIP_TYPES_COMPONENT_TEXT_ACTIVE'):$t('MEMBERSHIP_TYPES_COMPONENT_TEXT_DEACTIVE')}}</span>
                </div>

                <h2>{{$t("MEMBERSHIP_TYPES_COMPONENT_TITLE_PAID_MEMBERSHIP")}}:</h2>
                <CRow class="form-group form-paid-membership">
                  <CCol lg="12" md="12">
                    <table class="table table-striped table-price">
                      <thead>
                        <tr>
                          <th scope="col">{{$t("MEMBERSHIP_TYPES_COMPONENT_TEXT_COLUMN_NAME")}}</th>
                          <th
                            scope="col"
                          >{{$t("MEMBERSHIP_TYPES_COMPONENT_TEXT_COLUMN_PRICE",{currency: currency})}}</th>
                          <th scope="col">{{$t("MEMBERSHIP_TYPES_COMPONENT_TEXT_COLUMN_STATUS")}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in paidMemberships" :key="index">
                          <td>
                            <ValidationProvider
                              :name="$t(`MEMBERSHIP_TYPES_PAGE_FIELD_NAME_VALIDATE_NAME`, {index: index + 1})"
                              rules="required|max:100"
                            >
                              <div class="form-group" slot-scope="{ errors }" :key="index">
                                <input type="text" v-model="item.name" class="form-control" />

                                <span class="error-msg-wrap">
                                  <span class="error-msg">{{errors[0]}}</span>
                                </span>
                              </div>
                            </ValidationProvider>
                          </td>

                          <td>
                            <ValidationProvider :name="$t(`MEMBERSHIP_TYPES_PAGE_FIELD_NAME_VALIDATE_PRICE`, {index: index + 1})" rules="required|min_value:0">
                              <div
                                class="form-group group-price"
                                slot-scope="{ errors }"
                                :key="index"
                              >
                                <currency-input
                                  class="input-field form-control text-right"
                                  v-model="item.price"
                                  :precision="2"
                                />
                                <p>{{currency}}</p>
                                <span class="error-msg-wrap">
                                  <span class="error-msg">{{errors[0]}}</span>
                                </span>
                              </div>
                            </ValidationProvider>
                          </td>

                          <td>
                            <div class="btn-switch-wrapper">
                              <label class="btn-switch">
                                <input type="checkbox" class="checkbox" v-model="item.isEnabled" />
                                <span class="check-silder"></span>
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CCol>
                </CRow>

                <ValidationProvider name="MEMBERSHIP_TYPES_PAGE_FIELD_NAME_VALIDATE_ENABLED_UPGRADE" rules="required">
                  <CRow class="form-group form-upgrade" slot-scope="{ errors }">
                    <CCol lg="9" md="9">
                      <label>{{$t('MEMBERSHIP_TYPES_COMPONENT_CONTENT_UPGRADE_PAID_MEMBERS')}}:</label>
                    </CCol>
                    <CCol lg="3" md="3">
                      <div class="btn-switch-wrapper">
                        <label class="btn-switch">
                          <input
                            type="checkbox"
                            class="checkbox"
                            v-model="isEnabledUpgradePaidMembership"
                          />
                          <span class="check-silder"></span>
                        </label>
                      </div>
                      <span class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </span>
                    </CCol>
                  </CRow>
                </ValidationProvider>

                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton
                      v-if="isAllowedUpdating"
                      color="primary"
                      class="btn btn-primary"
                      @click="onSubmit"
                    >{{$t("MEMBERSHIP_TYPES_COMPONENT_BUTTON_SAVE")}}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { MembershipTypeName } from '@/enums';
import { SETTINGS_GET_MEMBERSHIP_TYPES } from '@/store/actions/setting';

export default {
  name: 'MembershipTypes',
  components: {
    VclList,
  },
  data() {
    return {
      isLoading: true,
      isProcessing: false,
      paidMemberships: [],
      isEnabledFreeMembership: false,
      isEnabledUpgradePaidMembership: false,
      isAllowedUpdating: false,
      currency: 'USD',
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    this.checkPermissions();

    await Promise.all([this.getMembershipTypeConfig(), this.getMembershipTypes()]);
    this.isLoading = false;
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'MEMBERSHIP_UPDATE_MEMBERSHIP_TYPE_CONFIG') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    async getMembershipTypes() {
      try {
        const result = await this.$http.get(endpoints.getMembershipTypes);
        const paidMemberships = result.data.map(item => {
          return {
            ...item,
            price: Number(item.price),
            currency_symbol: item.currency_symbol,
            isEnabled: item.is_enabled,
            displayOrder: item.display_order || 0,
          };
        });
        this.paidMemberships = _.sortBy(paidMemberships, 'displayOrder');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getMembershipTypeConfig() {
      try {
        const res = await this.$http.get(endpoints.getMembershipTypeConfig);
        this.isEnabledFreeMembership = res.data.membership_type_free_membership_flg;
        this.isEnabledUpgradePaidMembership = res.data.membership_type_upgrade_paid_member_flg;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBERSHIP_TYPES_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.updateMembershipConfig();
      });
    },
    async updateMembershipConfig() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      try {
        const membershipTypes = this.paidMemberships.map(item => {
          return {
            id: item.id,
            price: item.price,
            name: item.name,
            is_enabled: item.isEnabled,
          };
        });
        const data = {
          items: membershipTypes,
          membership_type_free_membership_flg: this.isEnabledFreeMembership,
          membership_type_upgrade_paid_member_flg: this.isEnabledUpgradePaidMembership,
        };

        const result = await this.$http.post(endpoints.updateMembershipTypeConfig, data);
        this.$store.dispatch(SETTINGS_GET_MEMBERSHIP_TYPES);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBERSHIP_TYPES_PAGE_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MEMBERSHIP_TYPES_PAGE_TEXT_NOTIFY_SUCCESS'),
        });

        this.isProcessing = false;
      } catch (err) {
        this.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBERSHIP_TYPES_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.update-membership-type-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }

    h2 {
      color: #657187;
      font-size: 16px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }

    .btn-switch-wrapper {
      margin: 0;
      line-height: 27px;
    }

    .form-group {
      margin-bottom: 0.5rem;
    }
    .form-free-membership {
      display: flex;
      flex: 0 0 100%;
      width: 100%;
      padding-left: 30px;
      margin: 10px 0 20px 0;
      label {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        color: #3e4b65;
      }
      .btn-switch-wrapper {
        width: 34px;
        margin: 0 20px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #657187;
        &.active {
          color: #07be07;
        }
        &.deactive {
          color: #ff0000;
        }
      }
    }
    .form-paid-membership {
      .table-price {
        border: 1px solid #dadada;
        .form-group {
          input {
            font-size: 14px;
            font-weight: 500;
            color: #3a4a7f;
          }
        }
        .btn-switch-wrapper {
          height: 34px;
          padding-top: 8px;
        }
        .group-price {
          display: flex;
          flex: 0 0 100%;
          width: 100%;
          flex-wrap: wrap;
          input {
            width: calc(100% - 35px);
            text-align: right;
          }
          p {
            width: 35px;
            font-size: 14px;
            font-weight: 300;
            color: #4d5666;
            line-height: 35px;
            margin: 0;
            padding-left: 5px;
          }
          .error-msg-wrap {
            width: 100%;
          }
        }
      }
    }
    .form-upgrade {
      margin-top: 25px;
      margin-bottom: 30px;
      label {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #3e4b65;
      }
      .btn-switch-wrapper {
        padding-top: 3px;
      }
    }
    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 4px;
        width: 145px;
        height: 35px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }
      }
    }
  }
}
</style>
